import { fromJS, Map as IMap } from 'immutable';
import md5 from 'js-md5';


export const PROMO_BY_CODE_REQUEST_STATE = 'promoByCodeRequestState';
export const PROMO_STORE_KEY = 'promo';
export const ACTIVE_PROMO = 'activePromo';
export const PROMO_ERROR = 'promoError';
export const PROMO_STATUS = 'promoStatus';
export const PROMO_REWARDS = 'rewards';
export const PROMO_REWARDS_STATE = 'requestState';
export const DIGITAL_PROMO_REWARD_CARDS = 'electronicRewardCards';
export const PLASTIC_PROMO_REWARD_CARDS = 'physicalRewardCards';
export const SHIPPING_PROMO_REWARDS = 'promotionalShippingData';
export const ACCESSORY_PROMO_REWARDS = 'promotionalAccessoryData';
export const DISCOUNT_PROMO_REWARD = 'totalDiscountRewardAmount';
export const CARTQUALIFIES = 'cartQualifies';
export const REJECTED_PROMO_CODE = 'rejectedPromoCode';
export const CART_ITEM_ID = 'parentCardCartToken';
export const REWARD_EMAIL = 'email';
export const REWARD_RECIPIENT_NAME = 'recipient_name';
export const CUSTOM_REWARD_CARD_RECIPIENT_STORE_KEY = 'customRewardRecipients';
export const PROMO_SELF_BONUS_KEY = 'selfBonus';
export const PROMO_NUDGES = 'promoNudges';
export const PROMO_INTENDED_RECIPIENT = {
    SENDER: 'Sender',
    RECIPIENT: 'Recipient'
};
export const PROMO_RECIPIENT_FIELDS = {
    RECIPIENT_NAME: 'recipientName',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    EMAIL: 'email',
    PHONE: 'phoneNumber',
    STREET1: 'street1',
    STREET2: 'street2',
    CITY: 'city',
    STATE: 'state',
    ZIP_CODE: 'postalCode',
    COUNTRY_CODE: 'countryCode'
};

export const PROMO_CERT_TYPE = {
    DIGITAL: 'ELEC',
    PHYSICAL: 'PLST'
};

export const REWARDS_REQUEST_STATE = {
    ACTIVE: 'active',
    FAILED: 'failed',
    SUCCESS: 'success'
};


export const parseRewardRequestSegmentData = (orderData = fromJS({}), promoCode, error) => {
    const firstEgiftCard = orderData.get('egiftCards', fromJS([{}])).first();
    return {
        amount: orderData.get('amount'),
        promoCode,
        egiftCardsQuantity: firstEgiftCard ? firstEgiftCard.get('quantity') : undefined,
        ...(error && { error: error.message })
    };
};


export const INVALID_PROMO_ERROR = 'promo_invalid';
export const EXPIRED_PROMO_ERROR = 'promo_expired';
export const SOLD_OUT_PROMO_ERROR = 'promo_sold_out';
export const PROMO_VALID_SUCCESS = 'promo_valid_success';


export const GET_DEFAULT_PROMO = '@@storefront/GET_DEFAULT_PROMO';
export const GET_INITIAL_PROMO = '@@storefront/GET_INITIAL_PROMO';
export const FINISH_GET_PROMO = '@@storefront/FINISH_GET_PROMO';
export const GET_PROMO_FOR_CODE = '@@storefront/GET_PROMO_FOR_CODE';
export const APPLY_ACTIVE_PROMO = '@@storefront/APPLY_PROMO';
export const RESET_PROMO = '@@storefront/RESET_PROMO';
export const APPLY_ACTIVE_PROMO_PRE_CART = '@@storefront/APPLY_ACTIVE_PROMO_PRE_CART';
export const RECEIVED_PROMO_REWARDS = '@@storefront/RECEIVED_PROMO_REWARDS';
export const SET_CUSTOM_REWARD_CARD_RECIPIENT = '@@storefront/SET_CUSTOM_REWARD_CARD_RECIPIENT';
export const SET_CUSTOM_REWARD_CARD_RECIPIENT_EMAIL = '@@storefront/SET_CUSTOM_REWARD_CARD_RECIPIENT_EMAIL';
export const SET_CUSTOM_REWARD_CARD_RECIPIENT_NAME = '@@storefront/SET_CUSTOM_REWARD_CARD_RECIPIENT_NAME';
export const CLEAR_CUSTOM_REWARD_CARD_RECIPIENT = '@@storefront/CLEAR_CUSTOM_REWARD_CARD_RECIPIENT';


export const SET_ACTIVE_PROMO = '@@storefront/SET_ACTIVE_PROMO';
export const SET_PROMO_ERROR = '@@storefront/SET_PROMO_ERROR';
export const SET_PROMO_STATUS = '@@storefront/SET_PROMO_STATUS';
export const SET_PROMO_REWARDS = '@@storefront/SET_PROMO_REWARDS';
export const REMOVE_PROMO_REWARDS = '@@storefront/REMOVE_PROMO_REWARDS';
export const SET_REJECTED_PROMO = '@@storefront/SET_REJECTED_PROMO';
export const CLEAR_REJECTED_PROMOS = '@@storefront/CLEAR_REJECTED_PROMO';
export const SET_REWARDS_REQUEST_STATE = '@@storefront/SET_REWARDS_REQUEST_STATE';
export const SET_PROMO_BY_CODE_REQUEST_STATE = '@@storefront/SET_PROMO_BY_CODE_REQUEST_STATE';
export const GET_PROMO_NUDGE_CONFIG_DATA = '@@storefront/GET_PROMO_NUDGE_CONFIG_DATA';
export const PROMO_NUDGE_SUCCESS = '@@storefront/PROMO_NUDGE_SUCCESS';
export const PROMO_NUDGE_FAILED = '@@storefront/PROMO_NUDGE_FAILED';

export const initialState = IMap();




export default function promoReducer(state = initialState, action) {
    switch (action.type) {
        case SET_ACTIVE_PROMO: {
            return state.set(ACTIVE_PROMO, fromJS(action.promo));
        }
        case SET_PROMO_ERROR: {
            const { codeOrDefault, error } = action;
            return state.update(PROMO_ERROR, (errorMap = IMap()) => errorMap.merge({ [codeOrDefault]: error }));
        }
        case SET_PROMO_STATUS: {
            return state.set(PROMO_STATUS, fromJS(action.status));
        }
        case SET_REWARDS_REQUEST_STATE: {
            return state.setIn([PROMO_REWARDS, PROMO_REWARDS_STATE], action.state);
        }
        case SET_PROMO_REWARDS: {
            return state
                .set(PROMO_REWARDS,
                    fromJS(action.rewards).merge({ [PROMO_REWARDS_STATE]: REWARDS_REQUEST_STATE.SUCCESS }));
        }
        case REMOVE_PROMO_REWARDS: {
            return state.delete(PROMO_REWARDS);
        }
        case SET_REJECTED_PROMO: {
            
            const stateWithoutActivePromo = state.delete(ACTIVE_PROMO);
            return stateWithoutActivePromo.set(REJECTED_PROMO_CODE, fromJS(action.rejectedPromoCode));
        }
        case CLEAR_REJECTED_PROMOS: {
            return state
                .delete(PROMO_ERROR)
                .delete(REJECTED_PROMO_CODE);
        }
        case SET_PROMO_BY_CODE_REQUEST_STATE: {
            return state.set(PROMO_BY_CODE_REQUEST_STATE, action.state);
        }
        case SET_CUSTOM_REWARD_CARD_RECIPIENT: {
            const { parentCardCartToken, recipientData } = action;
            return state.setIn([CUSTOM_REWARD_CARD_RECIPIENT_STORE_KEY, parentCardCartToken], recipientData);
        }
        case SET_CUSTOM_REWARD_CARD_RECIPIENT_EMAIL: {
            return state.setIn(
                [CUSTOM_REWARD_CARD_RECIPIENT_STORE_KEY,
                    action.parentCardCartToken, REWARD_EMAIL],
                action.recipientEmail
            );
        }
        case SET_CUSTOM_REWARD_CARD_RECIPIENT_NAME: {
            return state.setIn(
                [CUSTOM_REWARD_CARD_RECIPIENT_STORE_KEY,
                    action.parentCardCartToken, REWARD_RECIPIENT_NAME],
                action.recipientName
            );
        }
        case CLEAR_CUSTOM_REWARD_CARD_RECIPIENT: {
            return state.delete(CUSTOM_REWARD_CARD_RECIPIENT_STORE_KEY);
        }
        case PROMO_NUDGE_SUCCESS: {
            return state.set(PROMO_NUDGES, action.payload);
        }
        default:
            return state;
    }
}



export function setPromo(promo) {
    return {
        type: SET_ACTIVE_PROMO,
        promo
    };
}


export function clearActivePromo() {
    return {
        type: SET_ACTIVE_PROMO,
        promo: {}
    };
}


export function setPromoError(codeOrDefault, error) {
    return {
        type: SET_PROMO_ERROR,
        codeOrDefault,
        error
    };
}


export function setPromoStatus(status) {
    return {
        type: SET_PROMO_STATUS,
        status
    };
}


export function clearPromoStatus() {
    return {
        type: SET_PROMO_STATUS,
        status: null
    };
}


export function resetPromo() {
    return {
        type: RESET_PROMO
    };
}

export function getPromoNudgeData(promoCode, cartAmount, cardAmount) {
    return {
        type: GET_PROMO_NUDGE_CONFIG_DATA,
        promoCode,
        cartAmount,
        cardAmount
    };
}


export function getPromoNudgeConfigSuccess(payload) {
    return {
        type: PROMO_NUDGE_SUCCESS,
        payload
    };
}

export function getPromoNudgeConfigFailed() {
    return {
        type: PROMO_NUDGE_FAILED
    };
}


export const getDefaultPromo = () => ({ type: GET_DEFAULT_PROMO });


export const getPromoForCode = promoCode => ({
    type: GET_PROMO_FOR_CODE,
    promoCode
});


export const getInitialPromo = () => ({
    type: GET_INITIAL_PROMO
});

export const applyActivePromo = (isApplicableShipping = false) => ({
    type: APPLY_ACTIVE_PROMO,
    isApplicableShipping
});


export const applyActivePromoPreCart = (includeAllItems, skipDigital, isApplicableShipping = false, item = null) => ({
    type: APPLY_ACTIVE_PROMO_PRE_CART,
    includeAllItems,
    skipDigital,
    isApplicableShipping,
    item
});


export const setPromoRewards = (rewards, orderData, promoCode) => ({
    type: SET_PROMO_REWARDS,
    rewards,
    meta: {
        segment: {
            event: 'track',
            eventAction: `Promo request state: ${REWARDS_REQUEST_STATE.SUCCESS}`,
            eventData: parseRewardRequestSegmentData(orderData, promoCode)
        }
    }
});


export const removePromoRewards = () => ({
    type: REMOVE_PROMO_REWARDS
});


export const removeCustomRewardCardRecipients = () => ({
    type: CLEAR_CUSTOM_REWARD_CARD_RECIPIENT
});


export const setRejectedPromo = rejectedPromoCode => ({
    type: SET_REJECTED_PROMO,
    rejectedPromoCode,
    meta: {
        segment: {
            event: 'track',
            eventAction: `set rejected promo action called`,
            eventData: { rejectedPromoCode }
        }
    }
});


export const clearRejectedPromos = () => ({
    type: CLEAR_REJECTED_PROMOS
});


export const finishPromoLoad = () => ({ type: FINISH_GET_PROMO });


export const finishedGettingPromoRewards = () => ({
    type: RECEIVED_PROMO_REWARDS
});



export const setRewardsRequestActive = (orderData, promoCode) => ({
    type: SET_REWARDS_REQUEST_STATE,
    state: REWARDS_REQUEST_STATE.ACTIVE,
    meta: {
        segment: {
            event: 'track',
            eventAction: `Promo request state: ${REWARDS_REQUEST_STATE.ACTIVE}`,
            eventData: parseRewardRequestSegmentData(orderData, promoCode)
        }
    }
});


export const setRewardsRequestFailure = (orderData, promoCode, error) => ({
    type: SET_REWARDS_REQUEST_STATE,
    state: REWARDS_REQUEST_STATE.FAILED,
    meta: {
        segment: {
            event: 'track',
            eventAction: `Promo request state: ${REWARDS_REQUEST_STATE.FAILED}`,
            eventData: parseRewardRequestSegmentData(orderData, promoCode, error)
        }
    }
});

export const setPromoByCodeRequestState = requestState => ({
    type: SET_PROMO_BY_CODE_REQUEST_STATE,
    state: requestState
});

export const setCustomRewardCardRecipientForPromo = (parentCardCartToken, recipientData) => ({
    type: SET_CUSTOM_REWARD_CARD_RECIPIENT,
    parentCardCartToken,
    recipientData
});


export const setCustomRewardCardRecipientEmailForPromo = (parentCardCartToken, recipientEmail) => ({
    type: SET_CUSTOM_REWARD_CARD_RECIPIENT_EMAIL,
    parentCardCartToken,
    recipientEmail
});


export const setCustomRewardCardRecipientNameForPromo = (parentCardCartToken, recipientName) => ({
    type: SET_CUSTOM_REWARD_CARD_RECIPIENT_NAME,
    parentCardCartToken,
    recipientName
});

export const clearCustomRewardCardRecipientForPromo = () => ({
    type: SET_CUSTOM_REWARD_CARD_RECIPIENT
});


export function getPromoRecipientHash(reward) {
    const recipient = reward.get('recipient');
    const hashString = `${recipient.get('first_name')}
    ${recipient.get('last_name')}
    ${recipient.get('email')}
    ${recipient.get('street')}
    ${recipient.get('street2')}
    ${recipient.get('city')}
    ${recipient.get('state')}
    ${recipient.get('zip_code')}
    ${recipient.get('country_code')}`;
    return md5(hashString.replace(/\s/g, '').toLowerCase());
}
